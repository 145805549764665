// -------------------------------------------
//                   Map
// -------------------------------------------

@import "../../../styles/helpers";
$flow: right;

.panel-map {
  @include panel(60%);
  position: relative;
  overflow: hidden;
  .map-wrapper {
    @include panel-border-fix;
    height: 100%;
  }

  .map {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    display: block;
    cursor: default;
  }

  .feature-info {
    position: absolute;
    min-width: 100px;
  }
  .tooltip {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 4px 10px;
    box-shadow: 0 0 15px rgba(#000, .2);
    font-size: 16px;
    opacity: 1;
    &.priority-a { border-left: 4px solid #f44336; }
    &.priority-b { border-left: 4px solid #42A5F5; }
    &.priority-c { border-left: 4px solid #8BC34A; }
    &.priority-d { border-left: 4px solid #FFC107; }
  }
  .tooltip-row {
    padding: 2px;
    white-space: nowrap;
    &:first-letter {
      text-transform: capitalize;
    }
    td {
      padding: 2px;
    }
  }

  .polygon-select {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 140px;
    background-color: #75cae3;
    padding: 10px;
    opacity: 0.8;
    box-shadow: #00000066 0px 1px 4px -1px;
  }
}
.ol-control {
  background: none;
  padding: 0;
  button {
    font-weight: normal;
    background: #fff;
    color: #333;
    box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
    font-size: 18px;
    &:hover,
    &:focus {
      background: #fff;
    }
  }
}

.street-map{
  $size: 32px;
  position: absolute;
  padding: 5px;
  right: 45px;
  top: 6px;
  width: $size;
  height: $size;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px 1px;
  background: {
    color: #fff;
    image: url("../../../images/street-map-runner.png");
    size: 15px 30px;
    repeat: no-repeat;
    position: center;
  }
}

.pac-input {
  background-color: #fff;
  // font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin: 6px;
  width: 300px;

  &.search-box {
    position: absolute;
    z-index: 999;
    left: 40%;
    opacity: 0.7;

    &:focus {
      opacity: 1;
    }
    input {
      width: 100%;
      text-overflow: ellipsis;
      padding: 3px 13px;
    }

  }
}

.sensor-graphs-toggle {
  background: #fff;
  position: absolute;
  top: 135px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px -1px;
  min-height: 32px;
  min-width: 33px;

  &:hover {
    cursor: pointer;
  }

  &:before {
    @include ion-icon("stats-bars", 22px, #333);
    padding: 5px 8px 5px 9px;
  }
}

a.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
